<template>
  <div class="reference-user-report report flex flex-column top-divider">
    <div class="report-title">Сводная таблица по доступным курсам/тестам</div>
    <div class="report-block">
      <div @mousedown="mouseDownHandler" class="table-responsive">
        <table class="table-sm w-full">
          <thead>
            <tr>
              <th v-for="header of reportData[0]" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) of reportRows" :key="index">
              <td v-for="data of user" :key="data">{{ data }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import useDraggableOverflow from "@/mixins/useDraggableOverflow";

export default {
  name: "summary-report",
  setup() {
    const store = useStore();
    const reportData = computed(() => store.state.analytics.reportData),
      reportRows = computed(() =>
        !reportData.value ? [] : reportData.value.slice(1)
      );
    const { mouseDownHandler } = useDraggableOverflow();

    return {
      reportData,
      mouseDownHandler,
      reportRows,
    };
  },
};
</script>

<style lang="scss" scoped>
.reference-user-report {
  .support-table {
    margin-bottom: 25px;
    font-weight: 50;
  }

  .user-type-accord {
    background: #beffe0;
  }

  .user-type-accord_range {
    background: #fff1c5;
  }

  .user-type-not_accord {
    background: #ffd2d4;
  }
}

.sm-row {
  &__btn {
    min-width: 138px;
    margin-left: 15px;
  }
}
</style>
