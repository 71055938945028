<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">Настройка отчёта</p>
    <div class="report-options__body">
      <custom-select2
          label="Компания"
          name="company"
          v-model="selectedCompany.value"
          :options="allowedCompanies"
          :labelWidth="27"
      >
      </custom-select2>
      <custom-select-2
          v-if="selectedCompany.value"
          label="Группы"
          name="group_ids"
          :multiple="true"
          v-model="selectedGroups"
          :options="groupsOptions"
          :labelWidth="27"
      ></custom-select-2>
      <custom-select2
          v-if="selectedCompany.value"
          label="Пользователи"
          :multiple="true"
          name="users"
          :labelWidth="27"
          v-model="selectedUsers"
          :settings="usersAjax"
          :disabled="!!(selectedGroups.length && selectedGroups.includes('0'))"
      ></custom-select2>
      <p v-if="selectedGroups.length && selectedUsers.length" class="text-center mt-15 report-comment">
        В результате будут отображены все участники выбранных групп и выбранные
        пользователи
      </p>
    </div>
    <div
        v-if="selectedGroups.length || selectedUsers.length"
        class="report-options__btn flex justify-content-center"
    >
      <button @click="requestForReport" class="btn btn-accent">
        Получить таблицу
      </button>
      <button @click="requestForExcelReport" class="btn btn-accent">
        Получить excel-файл
      </button>
    </div>
    <div v-if="loading" class="preloader_wrapper">
      <mini-preloader/>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "@vue/runtime-core";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {useStore} from "vuex";
import {notify} from "@kyvg/vue3-notification";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import Preloader from "@/App.vue";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
import {reactive} from "@vue/reactivity";

export default {
  components: {MiniPreloader, Preloader, CustomSelect2},
  name: "general-report-parameters",
  setup(props, context) {
    const store = useStore();
    const selectedGroups = ref([]),
        selectedUsers = ref([]),
        groupsOptions = ref([]);
    const showExcelLink = ref(null);
    const groups = computed(() => store.state.group.groups);
    const {usersAjax} = useAjaxSelect();
    const loading = ref(false);
    const company = ref(null);
    const allowedCompanies = computed(() => store.state.company.analyticsAccessCompanies.map(
        (company) => ({id: company.id, text: (`${company.name} (${company.owner_name})`)})
    ));

    const selectedCompany = reactive({
      value: null
    });

    onBeforeMount(() => {
      store.dispatch("company/getAnalyticsAllowedCompanies");
    });

    onBeforeUnmount(() => {
      store.commit("company/setSelectedCompany", null)
    })

    const requestForReport = () => {
      if (selectedUsers.value.length > 0 || selectedGroups.value.length > 0) {

        const request = fillRequest();
        context.emit("reportRequest", request);
      } else {
        notify({
          type: "warning",
          title: "Выберите группы и/или пользователей",
        });
      }
    };

    const requestForExcelReport = () => {
      loading.value = true;
      const request = fillRequest();
      store
          .dispatch("analytics/getGeneralReportTableExcel", request)
          .then(({data}) => {
            window.open(`${process.env.VUE_APP_BACK}/${data}`, "Таблица")
            loading.value = false;
          }).catch(() => {
        loading.value = false
      });
    };

    const fillRequest = () => {
      let request = {};
      if (selectedGroups.value.length > 1 && selectedGroups.value.includes('0')) {
        selectedGroups.value = selectedGroups.value.filter((group) => group !== '0');
      }

      if (selectedGroups.value.length && !selectedGroups.value.includes('0')) {
        request.groups = selectedGroups.value.map((group) => Number(group));
      }

      if (selectedUsers.value.length) {
        request.users = selectedUsers.value.map((user) => Number(user));
      }

      if (selectedCompany.value) {
        request.company_id = Number(selectedCompany.value)
      }

      return request;
    };

    watch(() => selectedCompany.value,
        () => {
          store.commit('company/setSelectedCompany', selectedCompany.value);
          groupsOptions.value = [];
          selectedUsers.value = [];

          store.dispatch("group/getGroups", selectedCompany.value).then(() => {
            groupsOptions.value.push({id: 0, text: "Все сотрудники"});
            groupsOptions.value = groupsOptions.value.concat(groups.value.map((group) => {
              return {id: group.id, text: group.name};
            }));
          });
    });


    return {
      selectedGroups,
      groupsOptions,
      usersAjax,
      groups,
      requestForReport,
      selectedUsers,
      showExcelLink,
      requestForExcelReport,
      loading,
      company,
      allowedCompanies,
      selectedCompany
    };
  },
};
</script>

<style>
.preloader_wrapper {
  margin: 10% 0;
  text-align: center;
}
</style>
